import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b432b1c2 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _19654477 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _c9aa1200 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _00d9de63 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6fc2a1c8 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _11f0a474 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _77d05483 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _40208180 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _8a42e54c = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _72cfea74 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _e50a8882 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _2f000708 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _373f6034 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _3f480d00 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _2ba4449b = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _fc5c3e36 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _3b78a894 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _b432b1c2,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _19654477,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _c9aa1200,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _00d9de63,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _6fc2a1c8,
    name: "receipt"
  }, {
    path: "/search",
    component: _11f0a474,
    name: "search"
  }, {
    path: "/search-booking",
    component: _77d05483,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _40208180,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _8a42e54c,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _72cfea74,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _e50a8882,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _2f000708,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _373f6034,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _3f480d00,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _2ba4449b,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _fc5c3e36,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _3b78a894,
    name: "index"
  }, {
    path: "/dedicated",
    component: _3b78a894,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _11f0a474,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _2ba4449b,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _2f000708,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _6fc2a1c8,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
